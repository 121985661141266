<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Edward Daniel Vidal García                                    ###### -->
<!-- ###### @date: Marzo 2022                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template class="p-0">
  <v-layout>
    <!-- PRELOADER -->
    <div>
      <v-overlay :opacity="0.8" :value="overlay">
        <v-progress-circular indeterminate size="120">
          Cargando...
        </v-progress-circular>
      </v-overlay>
    </div>

    <v-container wrap v-if="banderaContador >= 0">
      <v-row class="divCard" justify="space-between">
        <v-col cols="12" md="6" sm="6" class="leftSide">
          <h4>DISPENSACIONES</h4>
          <header class="blueGlobal">

            <!-- Filtro con fechas -->
            <!--  -->
            <template>
              <v-row v-if="rolInicio == 'PICKING'">
                <v-col class="d-flex" cols="6" md="6" sm="6">
                  <v-btn x-small depressed type="submit" color="success" @click="dialogRebalancear = true">
                    REBALANCEAR PICKING</v-btn>
                </v-col>
                <v-col class="d-flex" cols="6" md="6" sm="6" v-if="rolInicio == 'PICKING'">
                  <v-btn x-small depressed type="submit" color="success" @click="dialogLiberarMedios = true">
                    LIBERAR MEDIOS</v-btn>
                </v-col>
              </v-row>
              <v-row class="filtroFechas">
                <v-col cols="12" md="6" sm="12" v-if="rolInicio == 'ADMIN' || rolInicio == 'REGENTE'">
                  <span>Tipo de identificación</span>
                  <v-select class="white--text white b-r-3" v-model="tipoSelected" :items="itemTipo" item-text="nombre"
                    item-value="id" return-object :disabled="(disabledFecha == false)">
                  </v-select>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <span>Identificación</span>
                  <v-text-field v-model="identificacionUsuario" class="inputFiltros" hide-details
                    @focusout="changeState(1)"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="rolInicio == 'ADMIN' || rolInicio == 'REGENTE'">
                  <v-menu v-model="menuDesde" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <span>Desde</span>
                      <v-text-field v-model="desde" prepend-icon="today" readonly v-bind="attrs" v-on="on"
                        class="inputFiltros" hide-details></v-text-field>
                    </template>
                    <v-date-picker locale="es-CO" v-model="desde" @input="menuDesde = false" @change="changeState(1)">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="rolInicio == 'ADMIN' || rolInicio == 'REGENTE'">
                  <v-menu v-model="menuHasta" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <span>Hasta</span>
                      <v-text-field v-model="hasta" prepend-icon="today" readonly v-bind="attrs" v-on="on"
                        class="inputFiltros" hide-details></v-text-field>
                    </template>
                    <v-date-picker locale="es-CO" v-model="hasta" @input="menuHasta = false" @change="changeState(1)">
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="12" sm="12" v-if="rolInicio == 'ADMIN'">
                  <span>Filtrar Bodega</span>
                  <v-select class="white--text white b-r-3" v-model="bodegaSelected" :items="itemBodegas"
                    item-text="nombre" @change="changeState(1)" item-value="codigoBodega" return-object>
                  </v-select>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <div class="divSelectFiltro">
                    <span>Filtrar estado</span>
                    <v-select v-if="rolInicio != 'ADMIN'" class="white--text white b-r-3" v-model="estadoSelect"
                      :items="estadoFiltro" @change="changeState(0)" item-text="name" item-value="id" text-color="white">
                    </v-select>
                    <v-select v-if="rolInicio == 'ADMIN'" class="white--text white b-r-3" v-model="estadoSelect"
                      :items="estadoFiltroAdmin" @change="changeState(1)" item-text="name" item-value="id"
                      text-color="white">
                    </v-select>
                  </div>
                </v-col>
              </v-row>
            </template>
            <v-row class="etiquetas mt-2">
              <v-col cols="6" md="6" sm="6" class="font-weight-black">
                <v-btn class="ma-2" color="success" type="submit" @click="changeState(1)" tabindex="8">
                  <v-icon small large color="white" class="font-weight-black">update</v-icon>
                  ACTUALIZAR
                </v-btn>
              </v-col>
              <v-col cols="6" md="6" sm="6" class="font-weight-black">
                <span v-if="rolInicio != 'ADMIN'">{{ bodega }} / </span>
                <span class="contador">Mostrando {{ contador }} de</span><span>100</span>
              </v-col>
            </v-row>
          </header>
          <v-row v-if="contador == 0">
            <v-col cols="12" md="12" sm="12">
              <v-alert dense type="success" align="center" justify="space-between" v-if="rolInicio != 'ADMIN'">
                <strong>¡Bien hecho!<br> No hay dispensaciones pendientes</strong>
              </v-alert>
              <v-img :aspect-ratio="16 / 9" v-bind:style="{
                'margin': 'auto',
                'width': '100%'
              }" src="@/assets/images/Success.jpg"></v-img>
            </v-col>
          </v-row>
          <v-row cols="12" md="12" sm="6" v-for="formula in formulas" :key="formula.id" class="mt-3">
            <v-card :id="'border' + formula.id_docuware" @click="set(formula.id_docuware, formula.nombreFormula)"
              elevation="1" width="100%" class="mt-3 border cardDispensacion brilloCard">
              <v-card-text>
                <v-row align="center" no-gutters class="encabezadoCard">
                  <v-col cols="12" md="1" sm="12" align="center">
                    <v-card v-if="formula.medio" v-bind:style="{
                      'background-color': formula.medio.color,
                      'color': formula.medio.color,
                      'width': '25px',
                      'height': '25px',
                    }" outlined tile>.
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="3" sm="4" align="left">
                    <v-card outlined tile color="grey lighten-3">
                      <span class="font-weight-black">Medio </span>
                      <span v-if="formula.medio">{{ formula.medio.nombre }} </span>

                    </v-card>
                  </v-col>
                  <v-col cols="12" md="8" sm="4" align="left">
                    <v-card outlined tile color="grey lighten-3">
                      <span class="font-weight-black">{{ formula.tipoDocumento }} / Usuario: </span>{{
                        formula.documento
                      }} - {{ formula.nombreAfiliado }} <v-icon small large color="purple darken-2"
                        class="font-weight-black" @click="verTraza(formula.id)">timeline</v-icon>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="9" sm="4" align="left">
                    <v-card outlined tile color="grey lighten-3">
                      <span class="font-weight-black">Estado: </span>
                      <span v-if="formula.estado == 'PENDING'">PICKING PENDIENTE POR INICIAR</span>
                      <span v-if="formula.estado == 'STARTED'">PICKING INICIADO</span>
                      <span v-if="formula.estado == 'REJECTEDDISPENSATION'">PICKING DEVUELTO A CAJA</span>
                      <span v-if="formula.estado == 'FINISHDISPENSATION'">PICKING FINALIZADO</span>
                      <span v-if="formula.estado == 'CREDIDNOTE'">PENDIENTE POR ELABORAR NOTA CREDITO</span>
                      <span v-if="formula.estado == 'CANCEL'">ANULADO POR DEVOLUCIÓN EN PICKING</span>
                      <span v-if="formula.estado == 'REJECTEDBOXDISPENSATION'">ENTREGA ANULADA EN CAJA</span>
                      <span v-if="formula.estado == 'ENDPHARMACEUTICALSERVICE'">ENTREGADO AL USUARIO</span>
                      <span v-if="formula.estado == 'ENDCREDITNOTE'">NOTA CREDITO FINALIZADA</span>
                      <span v-if="formula.estado == 'FINISHCANCEL'">ANULADO Y FINALIZADO</span>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="3" sm="6" align="left">
                    <v-card outlined tile color="grey lighten-3">
                      {{ new Date(formula.fechaRegistroDocuware).toLocaleString('es-us', {
                        day: 'numeric', month:
                          'long', year: 'numeric'
                      })
                      }}
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="6" sm="12" align="left">
                    <v-card outlined tile color="grey lighten-3">
                      <span class="font-weight-black">Código interno: </span>{{ formula.entrega }}
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6" sm="12" align="right" v-if="rolInicio == 'ADMIN'">
                    <v-card outlined tile color="grey lighten-3">
                      <span class="font-weight-black">Bodega: </span><span class="red--text font-weight-black">{{
                        formula.bodega.nombre }}</span>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6" sm="12" align="left">
                    <v-card outlined tile color="grey lighten-3">
                      <span class="font-weight-black">Caja: </span>{{ formula.usuarioCaja.nombre.toUpperCase() }}
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6" sm="12" align="left">
                    <v-card outlined tile color="grey lighten-3">
                      <span class="font-weight-black">Picking: </span>{{ formula.usuarioPicking.nombre.toUpperCase() }}
                    </v-card>
                  </v-col>
                </v-row>

                <v-row align="center" no-gutters class="encabezadoCard">
                  <v-col cols="12" md="3" sm="6" align="left">
                    <v-card outlined tile color="grey lighten-3">
                      <span class="font-weight-black">Domicilio: </span>
                      {{ formula.domicilio }}
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="3" sm="6" align="left">
                    <v-card outlined tile color="grey lighten-3">
                      <span class="font-weight-black">Requiere Factura: </span>
                      {{ formula.requiereFactura }}
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="3" sm="12" align="left">
                    <v-card outlined tile color="grey lighten-3">
                      <span class="font-weight-black">Faltante: </span>{{ formula.faltante }}
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="3" sm="6" align="center">
                    <v-card flex outlined tile color="grey lighten-3">
                      <span class="font-weight-black">
                        <v-icon color="blueTextGlobal">timer</v-icon>
                      </span><span class="temporizador" :id="'temporizador' + formula.id"></span>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row no-gutters class="grid" justify="space-around">
                  <v-col cols="12" md="12" sm="12" class="left_inner">
                    <v-simple-table dense class="table" cols="12">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th width="35%" class="text-left black--text font-weight-black">
                              PRODUCTO
                            </th>
                            <th width="20%" class="text-center black--text font-weight-black">
                              LABORATORIO
                            </th>
                            <th width="10%" class="text-center black--text font-weight-black">
                              LOTE
                            </th>
                            <th width="15%" class="text-center black--text font-weight-black">
                              FECHA VENCIMIENTO
                            </th>
                            <th width="10%" class="text-center black--text font-weight-black">
                              CÓDIGO
                            </th>
                            <th width="10%" class="text-center black--text font-weight-black">
                              CANTIDAD
                            </th>
                          </tr>
                        </thead>
                        <tbody class="tbody" :id="'tbody' + formula.id">
                          <tr v-for="item in JSON.parse(formula.detalleEntrega)" :key="item.cod">
                            <td width="35%">{{ item.nombre }}</td>
                            <td class="text-center" width="20%">
                              {{ item.laboratorio }}
                            </td>
                            <td class="text-center" width="10%">
                              {{ item.lote }}
                            </td>
                            <td class="text-center" width="15%">
                              {{ item.vencimiento }}
                            </td>
                            <td class="text-center" width="10%">
                              {{ item.cod }}
                            </td>
                            <td class="text-center" width="10%">
                              {{ item.cantidad }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="3" md="3" sm="3" class="mt-1" align="left" v-if="formula.contrato">
                    <v-card outlined tile>
                      <span class="font-weight-black">Contrato:</span>
                      <span>{{ formula.contrato }}</span>
                    </v-card>
                  </v-col>
                  <v-col cols="3" md="3" sm="3" class="mt-1" align="left" v-if="formula.nua">
                    <v-card outlined tile>
                      <span class="font-weight-black">NUA:</span>
                      <span>{{ formula.nua }}</span>
                    </v-card>
                  </v-col>
                  <v-col cols="5" md="5" sm="5" class="mt-1" align="left" v-if="formula.mipres">
                    <v-card outlined tile>
                      <span class="font-weight-black">Mipres:</span>
                      <span>{{ formula.mipres }}</span>
                    </v-card>
                  </v-col>
                  <v-col cols="5" md="5" sm="5" class="mt-1" align="left" v-if="formula.fechaFormulacion">
                    <v-card outlined tile>
                      <span class="font-weight-black">Fecha formulación:</span>
                      <span>{{ formula.fechaFormulacion }}</span>
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="11" sm="11" align="left" v-if="formula.observaciones">
                    <v-card outlined tile>
                      <span class="font-weight-black">Observaciones: </span><span class="red--text">
                        {{ formula.observaciones }}
                      </span>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="11" sm="11" align="left" v-if="formula.motivoDevolucionPicking">
                    <v-card outlined tile>
                      <span class="font-weight-black">Motivo devolución picking: </span><span class="red--text">
                        {{ formula.motivoDevolucionPicking.nombre }}
                      </span>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="11" sm="11" align="left" v-if="formula.motivoDevolucionCaja">
                    <v-card outlined tile>
                      <span class="font-weight-black">Motivo devolución caja: </span><span class="red--text">
                        {{ formula.motivoDevolucionCaja.nombre }}
                      </span>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <!-- Acciones Auxiliar picking -->
              <v-row v-if="rolInicio == 'PICKING'" no-gutters class="mt-1 mb-1 grid actions" justify="space-around">
                <v-col v-if="formula.estado == 'PENDING'" class="d-flex" cols="12" md="2" sm="12">
                  <v-btn x-small depressed :disabled="disabledIniciar == formula.estado" type="submit" color="success"
                    class="col-md-12"
                    @click="openDialog('Iniciar Picking', formula.id, formula.nombreAfiliado, 1, `Seleccionar medio para iniciar el picking de ${formula.nombreAfiliado}`)">
                    INICIAR</v-btn>
                </v-col>
                <v-col class="d-flex" cols="12" md="3" sm="12">
                  <v-btn v-if="formula.estado == 'STARTED'" x-small depressed :disabled="disabled == formula.estado"
                    class="col-md-12"
                    @click="openDialog('Devolver Picking', formula.id, formula.nombreAfiliado, 2, `El picking de ${formula.nombreAfiliado} será devuelto a la Caja de ${formula.usuarioCaja.nombre}`), motivoDevolucionDialog = true">
                    DEVOLVER A CAJA</v-btn>
                </v-col>
                <v-col v-if="formula.estado == 'STARTED'" class="d-flex" cols="12" md="2" sm="12">
                  <v-btn x-small depressed :disabled="disabled == formula.estado" type="submit"
                    class="col-md-12 fondoBoton white--text"
                    @click="openDialog('Finalizar Picking', formula.id, formula.nombreAfiliado, 3, `El picking de ${formula.nombreAfiliado} será finalizado y notificado a la Caja de ${formula.usuarioCaja.nombre}`)">
                    FINALIZAR</v-btn>
                </v-col>
              </v-row>

              <!-- Acciones Auxiliar caja -->
              <v-row v-if="rolInicio == 'CAJA'" no-gutters class="mt-1 mb-1 grid actions" justify="space-around">
                <v-col v-if="formula.estado == 'REJECTEDDISPENSATION'" class="d-flex" cols="12" md="2" sm="12">
                  <v-btn x-small depressed
                    @click="openDialog('Nota Crédito', formula.id, formula.nombreAfiliado, 4, `El picking de ${formula.nombreAfiliado} fue rechazado y se notificara a la Regente ${formula.nombreAfiliado} para realizar nota crédito`)"
                    class="col-md-12">NOTA CRÉDITO</v-btn>
                </v-col>

                <v-col v-if="formula.estado == 'REJECTEDDISPENSATION'" class="d-flex" cols="12" md="2" sm="12">
                  <v-btn x-small depressed
                    @click="openDialog('Anular', formula.id, formula.nombreAfiliado, 5, `El picking de ${formula.nombreAfiliado} sera anulado`)"
                    class="col-md-12">ANULAR
                  </v-btn>
                </v-col>

                <v-col v-if="formula.estado == 'FINISHDISPENSATION'" class="d-flex" cols="12" md="2" sm="12">
                  <v-btn x-small depressed
                    @click="openDialog('Anular', formula.id, formula.nombreAfiliado, 6, `El picking de ${formula.nombreAfiliado} sera anulado`)"
                    class="col-md-12">ANULAR
                  </v-btn>
                </v-col>

                <v-col v-if="formula.estado == 'FINISHDISPENSATION'" class="d-flex" cols="12" md="2" sm="12">
                  <v-btn x-small depressed
                    @click="openDialog('Entregar', formula.id, formula.nombreAfiliado, 7, `El picking de ${formula.nombreAfiliado} sera entregado`)"
                    type="submit" class="col-md-12 fondoBoton white--text">ENTREGAR</v-btn>
                </v-col>

                <v-col class="d-flex" cols="12" md="4" sm="12">
                  <v-btn v-if="formula.estado == 'REJECTEDDISPENSATION' || formula.estado == 'FINISHDISPENSATION'" x-small
                    depressed type="submit" class="col-md-12"
                    @click="openDialog('Devolver a picking', formula.id, formula.nombreAfiliado, 1, `El picking de ${formula.nombreAfiliado} sera devuelto a picking`, formula.medio.id)">
                    DEVOLVER A PICKING</v-btn>
                </v-col>
              </v-row>

              <!-- Acciones Auxiliar regente -->
              <v-row v-if="rolInicio == 'REGENTE'" no-gutters class="mt-1 mb-1 grid actions" justify="space-around">
                <v-col class="d-flex" cols="12" md="4" sm="12">
                  <v-btn v-if="formula.estado == 'CREDIDNOTE'" x-small depressed type="submit"
                    class="col-md-12 fondoBoton white--text"
                    @click="openDialog('Finalizar Nota Crédito', formula.id, formula.nombreAfiliado, 8, `¿La nota crédito de la entrega de ${formula.nombreAfiliado} fue realizada?`)">
                    FINALIZAR NOTA CRÉDITO</v-btn>
                </v-col>
              </v-row>

              <!-- Acciones Auxiliar administrativo -->
              <v-row v-if="rolInicio == 'ADMINISTRATIVO'" no-gutters class="mt-1 mb-1 grid actions"
                justify="space-around">
                <v-col class="d-flex" cols="12" md="4" sm="12">
                  <v-btn v-if="formula.estado == 'REJECTEDBOXDISPENSATION'" x-small depressed type="submit"
                    class="col-md-12 fondoBoton"
                    @click="openDialog('Devolver a caja', formula.id, formula.nombreAfiliado, 3, `El picking de ${formula.nombreAfiliado} sera devuelto a caja`)">
                    DEVOLVER A CAJA</v-btn>
                </v-col>
                <v-col class="d-flex" cols="12" md="4" sm="12">
                  <v-btn v-if="formula.estado == 'REJECTEDBOXDISPENSATION' || formula.estado == 'CANCEL'" x-small
                    depressed type="submit" class="col-md-12 fondoBoton white--text"
                    @click="openDialog('Finalizar anulación', formula.id, formula.nombreAfiliado, 9, `La anulacion de ${formula.nombreAfiliado} sera finalizada`)">
                    FINALIZAR ANULACIÓN</v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-btn block class="blueGlobal mt-3" @click="changeState(10)" v-if="contador > 0 && rolInicio != 'ADMIN'">
            Cargar más dispensaciones
          </v-btn>
          <v-btn block class="blueGlobal mt-3" @click="changeState(2)" v-if="contador > 0 && rolInicio == 'ADMIN'">
            Cargar más dispensaciones
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" sm="6" class="left_resizable boxPdf" v-bind:style="{
          'display': 'flex',
          'flex-wrap': 'wrap',
          'padding': '0 !important',
          'padding-left': '5px !important'
        }">
          <h4>FÓRMULA</h4>
          <v-divider></v-divider>

          <pdf v-for="i in numPages" :key="i" :src="src" :page="i" v-show='pdfView'></pdf>
          <div class="pdfClose">
            <p>
              <v-icon color="black" class="iconPdfIframe">picture_as_pdf</v-icon><br>
              No hay seleccionado ninguna dispensación
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-alert dense type="success" align="center" justify="space-between">
        <strong>¡Bien hecho!<br> No hay dispensaciones pendientes</strong>
      </v-alert>
      <v-img :aspect-ratio="16 / 9" v-bind:style="{
        'margin': 'auto',
        'width': '70%'
      }" src="@/assets/images/Success.jpg"></v-img>
    </v-container>
    <!-- Dialogo anulaciones, seleccionar, medio y finalizar dispensaciones -->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent scrollable max-width="600px">
        <v-card>
          <v-spacer></v-spacer>
          <v-card-title class="blueGlobal">
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text class="mt-3">
            <v-spacer></v-spacer>
            <i>{{ formTexto }}</i>
            <v-row align="center">
              <v-col cols="12" v-show='mediosDialog'>
                <v-select v-model="medioSelect" :items="medios" item-text="nombre" item-value="id"
                  label="Seleccione Medio" return-object>
                </v-select>
              </v-col>
              <v-col cols="12" v-show='motivoDevolucionDialog'>
                <v-select v-model="motivoDevolucionSelect" :items="motivoDevolucion" item-text="nombre" item-value="id"
                  label="Motivo Devolución" return-object>
                </v-select>
              </v-col>
              <v-col cols="12" v-show='observacionesDialog'>
                <v-textarea v-model="observaciones" label="Observaciones" filled auto-grow clear-icon="close">
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-row>
            <v-col class="preguntaDialog" cols="12" align="center">
              <span class="text-center black--text">{{ preguntaDialog }}</span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions class="d-flex flex-row-reverse">
            <v-btn class="ma-2 blueGlobal" type="submit" @click="sendEstado()">
              Si
            </v-btn>
            <v-btn class="ma-2" text @click="dialog = false">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Dialogo Rebalancear -->
    <v-row justify="center">
      <v-dialog v-model="dialogRebalancear" persistent scrollable max-width="600px">
        <v-card>
          <v-spacer></v-spacer>
          <v-card-title class="blueGlobal">
            <span class="text-h5">Rebalancear picking</span>
          </v-card-title>
          <v-row>
            <v-col class="preguntaDialog" cols="12" align="center">
              <span class="text-center black--text">¿Realmente deseas rebalancear?</span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions class="d-flex flex-row-reverse">
            <v-btn class="ma-2 blueGlobal" type="submit" @click="rebalancear()">
              Si
            </v-btn>
            <v-btn class="ma-2" text @click="dialogRebalancear = false">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Dialogo Liberar Medios -->
    <v-row justify="center">
      <v-dialog v-model="dialogLiberarMedios" persistent scrollable max-width="600px">
        <v-card>
          <v-spacer></v-spacer>
          <v-card-title class="blueGlobal">
            <span class="text-h5">Liberar Medios</span>
          </v-card-title>
          <v-row>
            <v-col class="preguntaDialog" cols="12" align="center">
              <span class="text-center black--text">¿Realmente deseas liberar medios?</span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions class="d-flex flex-row-reverse">
            <v-btn class="ma-2 blueGlobal" type="submit" @click="liberarMedios()">
              Si
            </v-btn>
            <v-btn class="ma-2" text @click="dialogLiberarMedios = false">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Dialogo de trazabilidad -->
    <v-row justify="center">
      <v-dialog v-model="dialogTrazabilidad" persistent scrollable max-width="100%">
        <v-card>
          <v-spacer></v-spacer>
          <v-card-title class="blueGlobal">
            <span class="text-h5">Trazabilidad</span>
          </v-card-title>
          <v-card-text class="mt-3">
            <v-row align="center">
              <v-col cols="12" md="12" sm="12" class="left_inner">
                <v-simple-table dense class="tableTrazabilidad" cols="12">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th width="10%" class="text-center black--text font-weight-black">
                          FECHA
                        </th>
                        <th width="13%" class="text-center black--text font-weight-black">
                          ESTADO
                        </th>
                        <th width="6%" class="text-center black--text font-weight-black">
                          ROL
                        </th>
                        <th width="10%" class="text-center black--text font-weight-black">
                          USUARIO
                        </th>
                        <th width="21%" class="text-center black--text font-weight-black">
                          OBSERVACIONES
                        </th>
                        <th width="20%" class="text-center black--text font-weight-black">
                          MOTIVO DEVOLUCION PICKING
                        </th>
                        <th width="20%" class="text-center black--text font-weight-black">
                          MOTIVO DEVOLUCION CAJA
                        </th>
                      </tr>
                    </thead>
                    <tbody class="tbody">
                      <tr v-for="item in trasabilizad" :key="item.id">
                        <td width="10%" class="text-center">
                          {{ new Date(item.fecha_creacion).toLocaleString('es-us', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                          })
                          }}
                        </td>
                        <td class="text-left" width="13%">
                          <span v-if="item.estado == 'PENDING'">PICKING PENDIENTE POR INICIAR</span>
                          <span v-if="item.estado == 'STARTED'">PICKING INICIADO</span>
                          <span v-if="item.estado == 'REJECTEDDISPENSATION'">PICKING DEVUELTO A CAJA</span>
                          <span v-if="item.estado == 'FINISHDISPENSATION'">PICKING FINALIZADO</span>
                          <span v-if="item.estado == 'CREDIDNOTE'">PENDIENTE POR ELABORAR NOTA CREDITO</span>
                          <span v-if="item.estado == 'CANCEL'">ANULADO POR DEVOLUCIÓN EN PICKING</span>
                          <span v-if="item.estado == 'REJECTEDBOXDISPENSATION'">ENTREGA ANULADA EN CAJA</span>
                          <span v-if="item.estado == 'ENDPHARMACEUTICALSERVICE'">ENTREGADO AL USUARIO</span>
                          <span v-if="item.estado == 'ENDCREDITNOTE'">NOTA CREDITO FINALIZADA</span>
                        </td>
                        <td class="text-left" width="6%">
                          {{ item.tipo_usuario }}
                        </td>
                        <td class="text-left" width="11%">
                          {{ item.usuario.toUpperCase() }}
                        </td>
                        <td class="text-center" width="20%">
                          {{ item.observaciones }}
                        </td>
                        <td class="text-center" width="20%">
                          <span v-if="item.motivDevolucionPicking">{{ item.motivDevolucionPicking.toUpperCase()
                          }}</span>
                        </td>
                        <td class="text-center" width="20%">
                          <span v-if="item.motivoDevolucionCaja">{{ item.motivoDevolucionCaja.toUpperCase()
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="d-flex flex-row-reverse">
            <v-btn class="ma-2" text @click="dialogTrazabilidad = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </v-layout>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { Role } from "@/router/role.js";
import "sweetalert2/dist/sweetalert2.min.css";
import "jquery-ui/ui/widgets/resizable";
import $ from "jquery";
import pdf from 'vue-pdf'

var x = '';
export default {
  name: "picking",
  components: {
    pdf
  },
  data() {
    return {
      desde: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      hasta: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      identificacionUsuario: "",
      menuDesde: false,
      modal: false,
      menuHasta: false,
      overlay: true,
      formTitle: "",
      preguntaDialog: "",
      select: "",
      formTexto: "",
      userRoles: {},
      userRolesBaseDatos: [],
      userRolesBaseDatosPermisos: {},
      roles: Role,
      formulas: [],
      trasabilizad: [],
      picking: [],
      disabled: "PENDING",
      disabledIniciar: "STARTED",
      contador: 0,
      banderaContador: 0,
      bodega: "",
      src: "",
      widthImage: "90%",
      dialog: false,
      dialogRebalancear: false,
      dialogTrazabilidad: false,
      dialogLiberarMedios: false,
      disabledFecha: false,
      estado: 0,
      id: 0,
      medioSelect: 0,
      estadoSelect: 0,
      medios: [],
      estadoFiltro: [],
      estadoFiltroAdmin: [],
      observaciones: '',
      mediosDialog: false,
      motivoDevolucionDialog: false,
      observacionesDialog: false,
      pdfView: false,
      motivoDevolucion: [],
      motivoDevolucionSelect: '',
      itemBodegas: [],
      itemTipo: ['AS', 'CC', 'CE', 'CN', 'NV', 'PA', 'PE', 'PT', 'RC', 'SC', 'TI'],
      bodegaSelected: 0,
      tipoSelected: 0,
      numPages: undefined,
      rolInicio: '',
      offset: 0,
      idMedio: 0,
      timer: '',
      x: ''
    };
  },
  methods: {
    ...mapMutations([
      "updateAuth",
      "hideNotify",
      "showSuccess",
      "showBusy",
      "hideBusy",
    ]),
    changeState(offset) {
      if (offset == 0 || offset == 1) {
        this.contador = 0
        this.offset = 0

      } else {
        this.offset += 10;
      }
      const params = {
        estado: this.estadoSelect,
        desde: this.desde + ' ' + '00:00',
        hasta: this.hasta + ' ' + '23:59',
        identificacionUsuario: this.identificacionUsuario,
        offset: this.offset,
        //bodega: this.bodegaSelected
      }
      if (offset == 1 || offset == 2) {
        console.log(this.bodegaSelected)
        if (this.bodegaSelected) {
          params['bodega'] = this.bodegaSelected
        }
        this.disabledFecha = true
      }
      console.log(params)
      this.overlay = true
      this.$http
        .post("msa-pharmaceutical-services/getAllFilter", params)
        .then((result) => {
          console.log(result.data)
          if (offset == 0) {
            this.formulas = result.data;
            this.contador = result.data.length;
          } else {
            if (offset == 1) {
              this.formulas = []
            }
            for (var j = 0; j < result.data.length; j++) {
              this.formulas.push(result.data[j]);
            }
            this.contador += result.data.length;
            this.overlay = false
          }

          if (this.contador == 0) {
            this.banderaContador = 0;
          }
          clearInterval(this.x);
          clearTimeout(this.timer);
          for (var i = 0; i < this.contador; i++) {
            console.log(this.contador)
            var id = this.formulas[i].id;
            var fechaIni = this.formulas[i].fechaRegistroDocuware;
            var fechaFini = this.formulas[i].fechaFinTranscripcion;
            if (this.formulas[i].fechaFinTranscripcion != null) {
              this.pararTemporizador(id, fechaIni, fechaFini);
            } else {
              this.temporizador(id, fechaIni, fechaFini);
            }
          }
          this.overlay = false
        });
    },
    verTraza(id) {
      this.dialogTrazabilidad = true;
      const idDispensacion = {
        id: id
      }

      this.$http
        .post("msa-pharmaceutical-services/listTraza", idDispensacion)
        .then((result) => {
          this.trasabilizad = result.data;
        });
    }, rebalancear() {
      this.overlay = true
      this.$http
        .post("msa-pharmaceutical-services/balancearCargas")
        .then((result) => {
          console.log(result.data);
          this.listPicking();
          this.overlay = false
          this.dialogRebalancear = false
          this.showSuccess("Realizado Correctamente");
        });
    }, liberarMedios() {
      this.overlay = true
      this.$http
        .post("msa-pharmaceutical-services/liberarMedios/" + this.enterprise.code)
        .then((result) => {
          console.log(result.data);
          this.overlay = false
          this.dialogLiberarMedios = false
          this.showSuccess("Realizado Correctamente");
        });
    }
    , openDialog(title, id, name, estado, mensaje, medio) {
      this.dialog = true;
      this.formTitle = title;
      this.formTexto = mensaje;
      this.estado = estado;
      this.id = id;
      this.preguntaDialog = "¿Desea " + this.formTitle + " de " + name + "?";
      this.idMedio = medio;

      if (this.estado == 1 || !this.title == "Devolver a picking") {
        this.listMedios();
        this.mediosDialog = true;
        this.motivoDevolucionDialog = false;
        this.observacionesDialog = false;
        this.motivoDevolucionDialog = false;
      } else {
        this.mediosDialog = false;
      }

      if (this.estado == 2) {
        this.observacionesDialog = true;
        this.motivoDevolucionDialog = true
        this.listMotivoDevolucion();
      } else {
        this.observacionesDialog = false;
        this.motivoDevolucionDialog = false
      }

      if (this.estado == 5 || this.estado == 6) {
        this.motivoDevolucionDialog = true;
        this.listMotivoDevolucion();
      }
    },
    set(id, nombreFormula) {
      this.overlay = true
      $('.pdfClose').hide();
      $(".border").removeClass("active");
      $(".border").addClass("brilloCard");
      $("#border" + id).addClass("active");
      $(".tbody").removeClass("bodyHeightAuto");
      $("#tbody" + id).addClass("bodyHeightAuto");

      const fileName = nombreFormula;

      this.$http
        .post("msa-pharmaceutical-services/getFile", fileName)
        .then((result) => {
          this.pdfView = true;
          var loadingTask = pdf.createLoadingTask("data:application/pdf;base64," + result.data);
          this.src = loadingTask;
          this.src.promise.then(pdf => {
            this.numPages = pdf.numPages;
          });
          this.overlay = false
        });
    },
    listPicking: function () {
      this.overlay = false
      const params = {
        estado: this.estadoSelect,
        desde: this.desde + ' ' + '00:00',
        hasta: this.hasta + ' ' + '23:59',
        offset: this.offset,
        identificacionUsuario: this.identificacionUsuario
      }


      console.log(params)
      this.$http
        .post("msa-pharmaceutical-services/getAllFilter", params)
        .then((result) => {
          console.log(result.data)
          this.formulas = result.data;
          this.contador = result.data.length;

          for (var i = 0; i < this.contador; i++) {
            var id = this.formulas[i].id;
            var fechaIni = this.formulas[i].fechaRegistroDocuware;
            var fechaFini = this.formulas[i].fechaFinTranscripcion;
            if (this.formulas[i].fechaFinTranscripcion != null) {
              this.pararTemporizador(id, fechaIni, fechaFini);
            } else {
              this.temporizador(id, fechaIni, fechaFini);
            }
          }
          this.overlay = false
        });
    },
    listMedios: function () {
      this.$http
        .post("msa-pharmaceutical-services/listMedios/" + this.enterprise.code)
        .then((result) => {
          this.medios = result.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendEstado() {
      this.dialog = false
      const picking = {
        id: this.id,
        estado: this.estado,
        observaciones: this.observaciones,
        rol: this.rolInicio
      };

      if (this.estado == 1) {
        picking['medio'] = {
          "id": this.medioSelect.id
        }
      }
      console.log("title " + this.formTitle);
      if (this.formTitle == "Devolver a picking") {
        console.log(this.idMedio)
        picking['medio'] = {
          "id": this.idMedio
        }
      }

      if (this.estado == 2 && this.motivoDevolucionSelect != "") {
        picking['motivoDevolucionPicking'] = {
          "id": this.motivoDevolucionSelect.id
        }
      }

      if (this.estado == 5 && this.motivoDevolucionSelect != "") {
        picking['motivoDevolucionCaja'] = {
          "id": this.motivoDevolucionSelect.id
        }
      }
      this.editarPicking(picking);
    },
    editarPicking: function (data) {
      this.overlay = true
      this.$http
        .post("msa-pharmaceutical-services/dispensationEdit", data)
        .then((result) => {
          console.log(result);
          this.dialog = false;
          this.listPicking();
          this.showSuccess("Realizado Correctamente");
          this.overlay = false
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listMotivoDevolucion: function () {
      this.$http
        .post("msa-pharmaceutical-services/listMotivoDevolucion")
        .then((result) => {
          this.motivoDevolucion = result.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    temporizador: function (id, fechaInicial) {
      // var fecha = "2022/05/03 15:37:25";
      var fechaIni = new Date(fechaInicial).getTime();
      this.x = setInterval(function () {
        var now = new Date().getTime();
        var t = now - fechaIni;
        var days = Math.floor(t / (1000 * 60 * 60 * 24));
        var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((t % (1000 * 60)) / 1000);
        var tiempo = '';
        if (days > 0) {
          tiempo = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
        } else {
          tiempo = hours + "h " + minutes + "m " + seconds + "s ";
        }
        document.getElementById("temporizador" + id).innerHTML = tiempo
      }, 1000);
      console.log(x);
    }, pararTemporizador: function (id, fechaInicial, fechaFinal) {
      // var fecha = "2022/05/03 15:37:25";
      var fechaIni = new Date(fechaInicial).getTime();
      var now = new Date(fechaFinal).getTime();
      var t = now - fechaIni;
      var days = Math.floor(t / (1000 * 60 * 60 * 24));
      var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((t % (1000 * 60)) / 1000);
      var tiempo = '';
      if (days > 0) {
        tiempo = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
      } else {
        tiempo = hours + "h " + minutes + "m " + seconds + "s ";
      }
      this.timer = setTimeout(function () {
        document.getElementById("temporizador" + id).innerHTML = '<span class="green--text">' + tiempo + '</span>';
      }, 1200);
    },
  },
  created() {
    $(".medio").css({
      "display": "none"
    });
    $(".observaciones").css({
      "display": "none"
    });
    $(".motivoDevolucion").css({
      "display": "none"
    });

    this.$http
      .post("msa-pharmaceutical-services/listAllRolesUsuarios")
      .then((result) => {
        console.log(result)
        this.overlay = false
        this.rolInicio = result.data[0].rol.rol.toUpperCase();
        this.rolInicio = this.rolInicio.replace("DISPENSACION_USUARIO_", "")
        this.userRolesBaseDatos.push(result.data[0].rol.rol);

        this.userRoles = this.userRolesBaseDatos

        if (this.rolInicio == "PICKING") {
          this.estadoFiltro.push({ id: 0, name: 'PICKINGS PENDIENTES POR INICIAR O INICIADOS' });
          this.estadoFiltro.push({ id: 1, name: 'PICKINGS PENDIENTES POR INICIAR' });
          this.estadoFiltro.push({ id: 2, name: 'PICKINGS INICIADOS' });
          this.estadoFiltro.push({ id: 3, name: 'PICKINGS FINALIZADOS' });
          this.estadoFiltro.push({ id: 4, name: 'PICKINGS DEVUELTOS' });
          this.estadoFiltro.push({ id: 5, name: 'MOSTRAR TODOS' });
          this.listPicking();
        } else if (this.rolInicio == "CAJA") {
          this.estadoFiltro.push({ id: 1, name: 'PICKINGS FINALIZADO' });
          this.estadoFiltro.push({ id: 0, name: 'PICKINGS PENDIENTES POR INICIAR' });
          this.estadoFiltro.push({ id: 8, name: 'PICKINGS EN ALISTAMIENTO' });
          this.estadoFiltro.push({ id: 2, name: 'PICKINGS DEVUELTOS A CAJA' });
          this.estadoFiltro.push({ id: 3, name: 'PENDIENTES POR ELABORACIÓN DE NOTA CRÉDITO' });
          this.estadoFiltro.push({ id: 4, name: 'ANULADOS POR DEVOLUCION EN PICKING' });
          this.estadoFiltro.push({ id: 5, name: 'ENTREGADAS ANULADAS EN CAJA' });
          this.estadoFiltro.push({ id: 6, name: 'ENTREGADOS AL USUARIO' });
          this.estadoFiltro.push({ id: 7, name: 'MOSTRAR TODOS' });
          this.listPicking();
        } else if (this.rolInicio == "ADMINISTRATIVO") {
          this.estadoFiltro.push({ id: 0, name: 'ANULADOS POR DEVOLUCIÓN EN PICKING' });
          this.estadoFiltro.push({ id: 1, name: 'ENTREGAS ANULADAS EN CAJA' });
          this.estadoFiltro.push({ id: 2, name: 'ANULADOS Y FINALIZADOS' });
          this.estadoFiltro.push({ id: 3, name: 'MOSTRAR TODOS' });
          this.listPicking();
        } else if (this.rolInicio == "REGENTE") {
          this.estadoFiltro.push({ id: 0, name: 'PICKINGS PENDIENTES POR INICIAR' });
          this.estadoFiltro.push({ id: 1, name: 'PICKINGS INICIADOS' });
          this.estadoFiltro.push({ id: 2, name: 'PICKINGS FINALIZADOS' });
          this.estadoFiltro.push({ id: 3, name: 'PICKINGS DEVUELTOS A CAJA' });
          this.estadoFiltro.push({ id: 4, name: 'PENDIENTES POR ELABORACIÓN DE NOTA CRÉDITO' });
          this.estadoFiltro.push({ id: 5, name: 'ANULADOS POR DEVOLUCION EN PICKING' });
          this.estadoFiltro.push({ id: 6, name: 'ENTREGADAS ANULADAS EN CAJA' });
          this.estadoFiltro.push({ id: 7, name: 'ENTREGADOS AL USUARIO' });
          this.estadoFiltro.push({ id: 8, name: 'NOTAS CRÉDITOS FINALIZADAS' });
          this.estadoFiltro.push({ id: 9, name: 'FALTANTES' });
          this.estadoFiltro.push({ id: 10, name: 'DOMICILIOS' });
          this.estadoFiltro.push({ id: 11, name: 'ANULADOS Y FINALIZADOS' });
          this.estadoFiltro.push({ id: 12, name: 'MOSTRAR TODOS' });
          this.listPicking();
          console.log(this.rolInicio);
        } else if (this.rolInicio == "ADMIN") {
          this.overlay = false
          this.estadoFiltroAdmin.push({ id: 0, name: 'MOSTRAR TODOS' });
          this.estadoFiltroAdmin.push({ id: 12, name: 'PICKINGS PENDIENTES POR INICIAR' });
          this.estadoFiltroAdmin.push({ id: 1, name: 'PICKINGS INICIADOS' });
          this.estadoFiltroAdmin.push({ id: 2, name: 'PICKINGS FINALIZADOS' });
          this.estadoFiltroAdmin.push({ id: 3, name: 'PICKINGS DEVUELTOS A CAJA' });
          this.estadoFiltroAdmin.push({ id: 4, name: 'PENDIENTES POR ELABORACIÓN DE NOTA CRÉDITO' });
          this.estadoFiltroAdmin.push({ id: 5, name: 'ANULADOS POR DEVOLUCION EN PICKING' });
          this.estadoFiltroAdmin.push({ id: 6, name: 'ENTREGADAS ANULADAS EN CAJA' });
          this.estadoFiltroAdmin.push({ id: 7, name: 'ENTREGADOS AL USUARIO' });
          this.estadoFiltroAdmin.push({ id: 8, name: 'NOTAS CRÉDITOS FINALIZADAS' });
          this.estadoFiltroAdmin.push({ id: 9, name: 'FALTANTES' });
          this.estadoFiltroAdmin.push({ id: 10, name: 'DOMICILIOS' });
          this.estadoFiltroAdmin.push({ id: 11, name: 'ANULADOS Y FINALIZADOS' });

          this.$http
            .post("msa-pharmaceutical-services/listBodegas")
            .then((result) => {
              this.itemBodegas = result.data;
            })
            .catch((error) => {
              console.log(error);
            });

          if (this.rolInicio == "ADMIN") {
            //NO SE ENVIA
            this.changeState(3);
          }
        }
      })
      .catch((error) => {
        this.overlay = false
        console.log(error);
      });

    //this.userRoles = this.auth.roles   


    this.$http
      .post("msa-pharmaceutical-services/nombreBodega")
      .then((result) => {
        this.bodega = result.data.replace("SERVICIO FARMACEUTICO", "");
      })
      .catch((error) => {
        console.log(error);
      });

    console.log(this.userRoles)

    navigator.serviceWorker.addEventListener('message', event => {
      console.log('Push Notification Recieved', event) // refresh code goes here   
      const idPicking = {
        id: event.data.firebaseMessagingData.data["gcm.notification.idPicking"]
      }

      this.$http
        .post("msa-pharmaceutical-services/listOneDispensation", idPicking)
        .then((result) => {
          this.formulas.push(result.data);
          this.contador += 1;
        })
        .catch((error) => {
          console.log(error);
        });


    })
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
  },
  mounted() {
    console.log(this.rolInicio)
    window.setTimeout(function () {
      $(".left_resizable").resizable({
        resize: function (event, ui) {
          ui.size.width = ui.originalSize.width;
        },
      });
    }, 1500);
  },
};
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.10.0/css/smoothness/jquery-ui-1.10.0.custom.min.css";

.fixed-bar {
  position: sticky;
  position: -webkit-sticky;
  /* for Safari */
  top: 6em;
  z-index: 2;
}

.height-table {
  height: 70px;
}

.blueGlobal {
  background: #0D47A1 !important;
  color: white !important;
}

.padding0 {
  padding: 0px !important;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  top: 0;
}

.divCard {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 0px;
}

.divCard>div {
  padding: 0 !important
}


.tituloPanel {
  background: #167bba;
  min-height: 30px;
  padding: 9px 24px !important;
}

.v-card__text {
  padding: 0px;
}

.v-card__actions {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.encabezadoCard {
  margin: 0 !important;
  border-bottom: 1px solid #9e9e9e;
}

.theme--light.v-sheet--outlined {
  border: none !important;
}

.encabezadoCard .v-card {
  padding: 3px;
}

.table {
  border: 1px solid rgb(205, 205, 205) !important;
}

table thead {
  display: block;
  width: 100%;
  background: #828282;
}

.tableTrazabilidad {
  border: 1px solid #c5c5c5;
}

.tableTrazabilidad thead {
  background: #c5c5c5;
}

table tbody {
  display: block;
  width: 100%;
  overflow-y: overlay;
  overflow-x: auto;
}

table tbody tr,
table thead tr {
  width: 100%;
  display: table;
}

table tbody tr td,
table thead tr th {
  display: table-cell;
  padding: 1px 2px;
  overflow: hidden;
  border-right: 1px solid #d4d4d4;
}

.tableTrazabilidad tbody tr td,
table thead tr th {
  padding: 3px !important;
}

table tbody tr td {
  font-size: 10px !important;
}

table tbody tr td:nth-last-child(1),
table thead tr th:nth-last-child(1) {
  border-right: none !important;
}

/* Resizable */
/* .grid {
		display: grid;
		grid-template-columns: 0.2fr 1fr;
		grid-template-rows: 1fr;
    grid-gap: 3px;
		position: relative;
	}	
	.left_inner {
		padding: 0;
		width: 50%;
		min-width: 60%;
		height: 100%;
		min-height: 100%;
	}

	.right_bottom_inner {
		padding: 0;
		width: 40%;
		min-width: 100%;
	} */


.cardFormula {
  height: 80vh;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap
}

.active {
  border: 2px solid #2196f3 !important;
  filter: brightness(1) !important;
}

.cardDispensacion:hover {
  border: 2px solid #2196f3 !important;
}

.border {
  border: 1px solid #a7a7a7;
}

.brilloCard {
  filter: brightness(0.6);
}

.bodyHeightAuto {
  height: auto !important;
}

.temporizador {
  color: #145081;
  font-weight: bold;
}

.container {
  padding: 0 !important
}

.pdfClose {
  /* display: flex; */
  align-items: center;
  text-align: center;
  width: 100%;
  flex-wrap: wrap
}

.iconPdfIframe {
  zoom: 3;
}

.pdfClose>p {
  width: 100%
}

v-simple-table tr:hover:not(.v-table__expanded__content) {
  background: red !important;
}

table tbody tr:hover {
  background: transparent !important;
}

header {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  height: auto !important;
  padding: 10px;
}

.row {
  margin: 0px !important
}

.v-select {
  height: 32px;
  width: 100%;
  padding-left: 8px !important;
  margin: 0;
  text-align: center;
  padding: 0;
}

.b-r-6 {
  border-radius: 6px !important;
}

.b-r-3 {
  border-radius: 3px !important;
}

.boxPdf {
  overflow: auto;
  height: 100vh;
}

.boxPdf>span {
  width: 100%;
}

.leftSide {
  height: 100vh;
  margin-bottom: 20px;
  overflow: auto;
}

.actions {
  padding-bottom: 3px;
  padding-top: 3px;
}

.v-text-field__details {
  display: none !important
}

h4 {
  text-align: center;
  width: 100%;
  height: 20px;
  border-bottom: 1px solid #d2d2d2;
  display: flex;
  align-items: center;
  justify-content: center
}

.divSelectFiltro {
  width: 100% !important
}

.nombreUsuario {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
}

.inputFiltros {
  background: white !important;
  padding: 0px 10px;
}

.fondoBoton {
  background-color: #0D47A1 !important;
}

.etiquetas {
  display: flex;
  width: 100%;

}

.etiquetas>div {
  display: flex;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  align-items: center;
}

.etiquetas>div:nth-child(2) {
  justify-content: right
}

.filtroFechas>div {
  padding-bottom: 1px !important;
  padding-top: 1px !important;
}

.contador {
  border-radius: 4px;
  margin: 0px 1px 0px 4px;
  /* height: 29px; */
  display: flex;
  align-items: center;
  justify-content: center;
}</style>